
const getAllConversions = async (sd, ed) => {
    let url = 'https://linkenstaff.com/conversions/';
    const token = JSON.parse(localStorage.getItem('user'))['auth_token'];

    if (sd !== undefined || ed !== undefined) {
        url = `${url}?sd=${sd}&ed=${ed}`;
    }

    const fetchWithTimeout = (url, options, timeout = 600000) => {
        return new Promise((resolve, reject) => {
            const timer = setTimeout(() => {
                reject(new Error('Request timed out'));
            }, timeout);

            fetch(url, options)
                .then(response => {
                    clearTimeout(timer);
                    resolve(response);
                })
                .catch(error => {
                    clearTimeout(timer);
                    reject(error);
                });
        });
    };

    const response = await fetchWithTimeout(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }, 600000); // Тайм-аут в миллисекундах (60 секунд)

    if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
    }

    const data = await response.json();
    return data;
};


export default getAllConversions;