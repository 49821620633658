import React, { useEffect, useState } from 'react';
import getAllConversions from "../../api/api.service";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, startOfToday, startOfYesterday, startOfWeek, startOfMonth, endOfToday, endOfYesterday, endOfWeek, endOfMonth } from 'date-fns';
import './Table.css'

const MyTable = () => {
    const [data, setData] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [dateOption, setDateOption] = useState('today');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, [dateOption, dateRange]);

    const fetchData = async () => {
        setIsLoading(true);
        setError(null);

        let start, end;

        switch (dateOption) {
            case 'today':
                start = format(startOfToday(), 'yyyy-MM-dd');
                end = format(endOfToday(), 'yyyy-MM-dd');
                break;
            case 'yesterday':
                start = format(startOfYesterday(), 'yyyy-MM-dd');
                end = format(endOfYesterday(), 'yyyy-MM-dd');
                break;
            case 'currentWeek':
                start = format(startOfWeek(new Date()), 'yyyy-MM-dd');
                end = format(endOfWeek(new Date()), 'yyyy-MM-dd');
                break;
            case 'currentMonth':
                start = format(startOfMonth(new Date()), 'yyyy-MM-dd');
                end = format(endOfMonth(new Date()), 'yyyy-MM-dd');
                break;
            case 'dateRange':
                if (startDate && endDate) {
                    start = format(startDate, 'yyyy-MM-dd');
                    end = format(endDate, 'yyyy-MM-dd');
                }
                break;
            default:
                break;
        }

        try {
            if (start && end) {
                const result = await getAllConversions(start, end);

                if (Array.isArray(result)) {
                    const modifiedResult = result.map(item => {
                        const crPotential = ((item.sca_res.deps + item.centumedDeps) / item.sca_res.regs) * 100;
                        const crFact = (item.sca_res.deps / item.sca_res.regs) * 100;
                        const centum = (item.centumedDeps / (item.centumedDeps + item.sca_res.deps)) * 100;

                        return {
                            ...item,
                            crPotential: isNaN(crPotential) ? 0 : crPotential.toFixed(2),
                            crFact: isNaN(crFact) ? 0 : crFact.toFixed(2),
                            centum: isNaN(centum) ? 0 : centum.toFixed(1)
                        };
                    });

                    setData(modifiedResult);
                } else {
                    setData([]);
                }
            }
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDateOptionChange = (event) => {
        setDateOption(event.target.value);
        if (event.target.value !== 'dateRange') {
            setDateRange([null, null]);
        }
    };

    const calculateTotals = () => {
        return data.reduce((totals, item) => {
            totals.regs += item.sca_res.regs;
            totals.centumedRegs += item.centumedRegs;
            totals.deps += item.sca_res.deps;
            totals.centumedDeps += item.centumedDeps;
            return totals;
        }, { regs: 0, centumedRegs: 0, deps: 0, centumedDeps: 0 });
    };

    const totals = calculateTotals();

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Select
                    value={dateOption}
                    onChange={handleDateOptionChange}
                    sx={{ mr: 2 }}
                >
                    <MenuItem value="today">Сегодня</MenuItem>
                    <MenuItem value="yesterday">Вчера</MenuItem>
                    <MenuItem value="currentWeek">Текущая неделя</MenuItem>
                    <MenuItem value="currentMonth">Текущий месяц</MenuItem>
                    <MenuItem value="dateRange">Интервал дат</MenuItem>
                </Select>
                {dateOption === 'dateRange' && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => setDateRange(update)}
                            isClearable={true}
                            dateFormat="yyyy-MM-dd"
                            customInput={<TextField />}
                        />
                    </Box>
                )}
                <Button variant="contained" color="primary" onClick={fetchData} sx={{ ml: 2 }}>
                    Обновить
                </Button>
            </Box>
            {isLoading ? (
                <p>Загрузка...</p>
            ) : error ? (
                <p>Произошла ошибка: {error.message}</p>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Aff</TableCell>
                                <TableCell align="right">Regs</TableCell>
                                <TableCell align="right">Centumed Regs</TableCell>
                                <TableCell align="right">Deps</TableCell>
                                <TableCell align="right">Centumed Deps</TableCell>
                                <TableCell align="right">CR Potential</TableCell>
                                <TableCell align="right">CR Fact</TableCell>
                                <TableCell align="right">CENTUM</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(data) && data.length > 0 ? (
                                data.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.affiliate_id}
                                        </TableCell>
                                        <TableCell align="right">{row.sca_res.regs}</TableCell>
                                        <TableCell align="right">{row.centumedRegs}</TableCell>
                                        <TableCell align="right">{row.sca_res.deps}</TableCell>
                                        <TableCell align="right">{row.centumedDeps}</TableCell>
                                        <TableCell align="right">{row.crPotential} %</TableCell>
                                        <TableCell align="right">{row.crFact} %</TableCell>
                                        <TableCell align="right">{row.centum}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        Данные не найдены
                                    </TableCell>
                                </TableRow>
                            )}
                            {data.length > 0 && (
                                <TableRow className="yellow-bg">
                                    <TableCell component="th" scope="row">
                                        Total
                                    </TableCell>
                                    <TableCell align="right">{totals.regs}</TableCell>
                                    <TableCell align="right">{totals.centumedRegs}</TableCell>
                                    <TableCell align="right">{totals.deps}</TableCell>
                                    <TableCell align="right">{totals.centumedDeps}</TableCell>
                                    <TableCell align="right">
                                        {((totals.deps + totals.centumedDeps) / totals.regs * 100).toFixed(2)} %
                                    </TableCell>
                                    <TableCell align="right">
                                        {(totals.deps / totals.regs * 100).toFixed(2)} %
                                    </TableCell>
                                    <TableCell align="right">
                                        {((totals.centumedDeps / (totals.centumedDeps + totals.deps)) * 100).toFixed(1)}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default MyTable;
